

#foot_info{
  width:100%;
  text-align:center;
  padding-top:50px;
  padding-bottom:90px;
}


#contact .page-container{
  padding-top:100px;
  overflow: hidden;
}

#contact .Description{
  max-width:380px;
}

#contact .Description icon{
  position:relative;
  line-height:.5em;
}


.Contact-form{
  width:100%;
  max-width:500px;
  float:right;
  position:relative;
  top:-105px;
  padding-right:11%;
}


.Contact-form input{
  width:calc(50% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  color:#2F373F;
  height:60px;
  background:none;
  padding:10px 20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input::placeholder, .Contact-form textarea::placeholder{
  color:#bbb;
}

.Contact-form textarea{
  width:calc(100% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  background:none;
  color:#222222;
  height:100px;
  padding:20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input[type="Submit"]{
  background:white;
  width:120px;
  border-radius:5px;
  height:50px;
  box-shadow: 1px 1px 0px #2F373F;
  float:right;
  transition: background-color .3s;
  cursor:pointer;
}

.Contact-form input[type="Submit"]:hover{
  background-color: #eee;
  color:#2F373F;
}


#hellopress_link{
  color:white;
  text-decoration:none;
  font-weight:600;
  display:inline-block;
  margin-top:18px;
  font-size:.9em;
  line-height:20px;
  padding:3px 12px 4px 12px;
  background:#446;
  border-radius:100px;
  margin-left:-16px;
}

#hellopress_link:hover{
  background:#0784f8;
}



@media(max-width:1400px){
  .Contact-form {
    top: -105px;
    padding-right: 0px;
  }
}


@media(max-width:1200px){
  .Contact-form {
    width:50%;
  }
  #contact .Description{
    width:45%;
  }
}


@media(max-width:800px){
  #contact .Description{
    width:100%;
    max-width:400px;
  }
  .Contact-form {
    width: 100%;
    max-width: 500px;
    position: relative;
    padding-right: 0;
    margin: auto;
    margin-top: 160px;
    float:none;
  }
}