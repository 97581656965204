
#order_main_module{
  width:100%;
  min-height:900px;
  background-size:cover;
  margin-top:50px;
  margin-bottom:140px;
  padding-bottom:60px;

}

#order .section_title::before, #order .section_title::after {
  left: 30%;
  right: 30%;
}





#order .toggle_tab{
  max-width:400px;
  padding-left:15px;
}

#order .toggle_tab .dot{
  top:11px;
  left:52.5%;
}

#order .toggle_tab .tab{
  width:50%;
  font-size:1.4em!important;
}