

#hero{
  position:relative;
  height:calc(80vh);
  min-height:600px;
}

#hero_fruit{
  width:88%;
  right:-335px;
  position:absolute;
  margin-top:10px;
}

#hero_text{
  position:absolute;
  left:-80px;
  top:95px;
  z-index:1000;
  text-align:center;
}

#hero_text .one{
  color:#FAB686;
}
#hero_text .two{
  color:#FFA178;
}
#hero_text .three{
  color:#F9866D;
}

#hero_maintext{
  font-weight:700;
  font-size:5em;
  font-family:"Barlow Semi Condensed", sans-serif;
  line-height:1.3em;
  text-shadow: 0px 0px 100px white;
}

#hero_maintext::before {
  content: "";
  top:-25px;
  left:28%;
  right:28%;
  border-top:2px solid #F6C995;
  position:absolute;
}

#hero_subtext{
  position:absolute;
  bottom:-52px;
  padding:5px;
  background:white;
  width:30%;
  left:35%;
  text-align:center;
  font-size:1.3em;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-weight:800;
  color:#F97765;
}

#hero_maintext::after {
  content: "";
  bottom:-35px;
  left:28%;
  right:28%;
  border-bottom:2px solid #F97765;
  position:absolute;
}



#typewriter{
  display:inline-block;
  height:1.2em;
  vertical-align:top;
  overflow:hidden;
  text-align:center;
}

.word{
  display:block;
}



#type_inner{
  animation: example 10s ease-in-out 2s infinite;
}
/*
#typewriter .word{
  animation: example 5s ease-in-out 0s infinite;
}*/
/*
@keyframes example {
  0% {transform: translateY(0em);}
  4% {transform: translateY(-1.3em);}
  12% {transform: translateY(-1.3em);}
  16% {transform: translateY(-2.6em);}
  24% {transform: translateY(-2.6em);}
  28% {transform: translateY(-3.9em);}
  36% {transform: translateY(-3.9em);}
  40% {transform: translateY(-5.2em);}
  48% {transform: translateY(-5.2em);}
  52% {transform: translateY(-6.5em);}
  60% {transform: translateY(-6.5em);}
  64% {transform: translateY(-7.8em);}
  72% {transform: translateY(-7.8em);}
  76% {transform: translateY(-9.1em);}
  84% {transform: translateY(-9.1em);}
  88% {transform: translateY(-10.4em);}
  96% {transform: translateY(-10.4em);}
  100% {transform: translateY(-10.4em);}
}*/

@keyframes example {
  0% {transform: translateY(0em);}
  8% {transform: translateY(-1.3em);}
  20% {transform: translateY(-1.3em);}
  28% {transform: translateY(-2.6em);}
  40% {transform: translateY(-2.6em);}
  48% {transform: translateY(-3.9em);}
  60% {transform: translateY(-3.9em);}
  68% {transform: translateY(-5.2em);}
  80% {transform: translateY(-5.2em);}
  88% {transform: translateY(-6.5em);}
  100% {transform: translateY(-6.5em);}
}

@keyframes example2 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}

@keyframes example3 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}






#how_it_works{
  padding-top:20px;
}

.section_title{
  font-weight:800;
  font-size:2.4em;
  font-family:"Barlow Semi Condensed", sans-serif;
  line-height:1.3em;
  width:100%;
  max-width:500px;
  text-align:center;
  position:relative;
  margin:auto;
  margin-top:100px;
  z-index: 100;
}

.section_title::before {
  content: "";
  top:-25px;
  left:20%;
  right:20%;
  border-top:2px solid #222222;
  position:absolute;
}

.title_subtext{
  position: absolute;
  top: -60px;
  padding: 5px;
  background: white;
  width: 20%;
  left: 40%;
  text-align: center;
  font-size: .53em;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  color: #222222;
  z-index: 100;
}

.section_title::after {
  content: "";
  bottom:-27px;
  left:20%;
  right:20%;
  border-bottom:2px solid #222222;
  position:absolute;
}







.section_body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top:60px;
  margin-bottom: 100px;
}

.column_body{
  position:relative;
  z-index:10;
}

.col3 .col{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 33.33%;
  flex: 1;
  box-sizing:border-box;
  padding:40px;
  position:relative;
}

.head_image{
  height:270px;
  margin:0px auto;
  padding-bottom:40px;
  z-index:1;
  mix-blend-mode: darken;
}

#how_it_works .watercolor{
  position:absolute;
  z-index:0;
}
#how_it_works .watercolor.one{
  width:160%;
  top:-120px;
  left:-45%;
}
#how_it_works .watercolor.two{
  width:120%;
  top:40px;
  left:-10%;
}
#how_it_works .watercolor.three{
  width:190%;
  top:-180px;
  right:-55%;
}


.column_body>.title{
  font-size:1.35em;
  font-weight:600;
  text-align:center;
  margin-bottom:15px;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.list .title{
  font-weight:bold;
  font-family: 'Caveat', cursive;
  font-size:1.4em;
  margin-top:15px;
  margin-bottom:8px;
}

.list .title>img{
  display:inline-block;
  vertical-align: bottom;
  width:36px;
  margin-right:5px;
  margin-left:-6px;
  margin-bottom:0px;
}

.list .list_item{
  padding-bottom:5px;
}







/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/



  

@media(max-width:1400px){
  .head_image{
    height:200px;
  }
  #how_it_works .col3 .col{
    padding:30px;
  }

  #hero_text {
    max-width: 600px;
  }
  #hero_maintext{
    font-size:4.2em;
    left:40px;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
  }
  #how_it_works{
    margin-top:-80px;
  }
}

@media(max-width:1200px){
  .column_body>.title {
    text-align:left;
  }
  .head_image{
    height:160px;
  }

  #hero{
    height:65vh;
  }
  #hero_text{
    margin-left:60px;
  }
  #how_it_works{
    margin-top:30px;
  }
  #hero_fruit{
    right:-250px;
  }
  .list .title>img{
    margin-bottom:-3px;
  }
}

@media(max-width:1000px){
  .head_image{
    height:140px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 120px);
  }
  #hero_text {
    position: relative;
    left: unset;
    top: 80px;
    margin: auto;
    text-align: center;
    max-width: 550px;
  }
  #hero_maintext{
    font-size:4em;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
  }
  #hero_fruit{
    width:100%;
    margin-top:160px;
    margin-right:-30px;
    right:0px;
  }
  #how_it_works{
    margin-top:25%;
  }

  
}

@media(max-width:800px){
  .head_image{
    height:200px;
  }
  .col3{
    display:block;
  }
  .col3>.col{
    width:100%;
  }
  #seasonal_chart .chart_labels{
    width:200px;
    font-size:1.2em;
  }
  #seasonal_chart .chart_bars{
    width: calc(100% - 200px);
  }
  #seasonal_chart .chart_columns{
    width: calc(100% - 200px);
    left:200px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 140px);
  }
  
  
}

@media(max-width:600px){

  #how_it_works{
    margin-top:-10%;
  }

  .section_body.col3 .col{
    padding:30px 0px;
  }

  #how_it_works .watercolor{
    opacity:.7;
  }

  #how_it_works .watercolor.one{
    width: 140%;
    top: -40px;
    left: -20%;
  }

  #how_it_works .watercolor.three{
    width: 180%;
    top: -55px;
    right: -35%;
  }

  #hero{
    height: 65vh;
  }
  #hero_maintext{
    font-size:2.35em;
  }
  #typewriter #type_inner span.word{
    line-height:1.30em;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
    left:32%;
    width:33%;
  }
  #hero_text {
    position: relative;
    left: unset;
    top: 60px;
    margin: auto;
    text-align: center;
    max-width: 300px;
  }
  #hero_fruit{
    width:100%;
    margin-top:160px;
    margin-right:-10px;
    right:0px;
  }

  #seasonal_chart .chart_labels{
    width:160px;
    font-size:1.2em;
  }
  #seasonal_chart .chart_bars{
    width: calc(100% - 160px);
  }
  #seasonal_chart .chart_columns{
    width: calc(100% - 160px);
    left:160px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 40px);
  }

  #farmers_hero .carousel_outer .farmer{
    background:none;
    border:none;
  }

  #farmers_hero .farmer .col2.img_outer {
    padding:10px;
    padding-bottom:30px;
  }

  #farmers_hero .farmer .col2.text_outer {
    padding:10px;
  }

  #farmers_hero .carousel_outer .left_arrow{
    width: 50px;
    left: -55px;
  }
  #farmers_hero .carousel_outer .right_arrow{
    width: 50px;
    right: -55px;
  }
}

@media(max-width:450px){

}