


#about .section_title::before, #about .section_title::after {
  left: 30%;
  right: 30%;
}

#founder_section{
  margin-top:50px;
  display: flex;
}

.worker_section{
  margin-top:50px;
  display: flex;
}

#about .col2{
  vertical-align: middle;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex: 1;
  padding:7%;
  box-sizing:border-box;
}

#founder_section .wrap{
}

.col2 .name{
  font-weight:600;
  font-size:1.5em;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom:20px;
  padding-right:30px;
}

.col2 img{
  box-sizing: border-box;
  width: 100%;
}

#philosophy .col2 .name{
  text-align:center;
}

#philosophy .section_title::before, #philosophy .section_title::after{
  display:none;
}

#philosophy .section_title img{
  width:120px;
}

#philosophy .section_title .description{
  font-size:18px;
  font-family:'Barlow',sans-serif;
  line-height:22px;
  font-weight:400;
  margin-top:15px;
  text-align:left;
}

#philosophy .section_title .title_text{
  margin-top:10px;
}

#philosophy .col2{
  padding:4% 7%;
}

#philosophy .section_body .name>img{
  display:inline-block;
  vertical-align: bottom;
  width:40px;
  margin-right:10px;
  margin-bottom:-5px;
}