
#about_overview{
  margin-top:0px;
  margin-bottom:400px;
}

.about_card{
  width:calc(30% + 300px);
  box-sizing:border-box;
  padding:50px;
  background:white;
  border: 1px solid#D6D6DE;
  border-radius:4px;
  margin-top:30%;
  position:relative;
  z-index:10;
}

.about_card.right{
  left:calc(70% - 300px);
}

.list-item{
  display:inline-block;
  margin-top:15px;
  margin-left:25px;
}

.about_card .title{
  font-size:2.4em;
  position:relative;
  margin:auto;
  margin-top:-140px;
  height:0px;
  text-align:center;
  font-weight:700;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.about_card .description{
  position:relative;
  margin:auto;
  margin-top:150px;
}

.about_card .link{
  position:relative;
  margin:auto;
  margin-top:25px;
  text-align:right;
  font-size:1.2em;
  color:#F9866D;
  font-weight:600;
  cursor:pointer;
}

.about_card_image{
  position:relative;
  width:65%;
  left:30%;
  margin-top:-25%;
  z-index:0;
  border-radius: 4px;
  z-index:9;
}

.about_card_image.left{
  left:0px;
}


.watercolor_bg{
  position:absolute;
  mix-blend-mode: darken;
  z-index:0;
}

.watercolor_fruit{
  position:absolute;
  z-index:1;
  opacity:.8;
}

.watercolor_bg.one{
  width:100%;
  right:-30%;
  top:-12%;
}

.watercolor_fruit.one{
  width:40%;
  right:-10%;
  top:-10%;
}

.watercolor_bg.two{
  width:100%;
  left:-30%;
  top:11%;
}

.watercolor_fruit.two{
  width:30%;
  left:-10%;
  top:20%;
}

.watercolor_fruit.three{
  width:60%;
  right:-30%;
  margin-top:10%;
}


.watercolor_fruit.four{
  width:50%;
  right:-20%;
  margin-top:20%;
}

.watercolor_bg.three{
  width:85%;
  right:-15%;
  margin-top:85%;
}

.watercolor_fruit.five{
  width:55%;
  left:0%;
  margin-top:-8%;
}

.watercolor_bg.five{
  width:100%;
  right:-30%;
  margin-top:-20%;
}





/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){

}

@media(max-width:1200px){

}

@media(max-width:1000px){



}

@media(max-width:800px){


  #about_overview{
    margin-top:200px;
    margin-bottom:200px;
  }

  .about_card .title{
    font-size:2em;
    margin-top:-40px;
  }

  .about_card .description{
    margin-top:80px;
  }

  .about_card{
    width:100%;
    background:none;
    border:0px;
    padding:30px;
    padding-bottom:50px;
  }

  .about_card.right{
    left:0px;
  }


  .about_card_image{
    width:100%;
    left:0px;
    margin-bottom:100px;
    margin-top:0px;
  }

  .about_card_image.left{
    left:0px;
  }


  .watercolor_fruit{
    opacity:.8;
  }

.watercolor_bg.one{
  width:120%;
  right:-30%;
  top:-6%;
}

.watercolor_fruit.one{
  width:40%;
  right:-10%;
  top:-4%;
}

.watercolor_bg.two{
  width:140%;
  left:-40%;
  top:200px;
}

.watercolor_fruit.two{
  width:30%;
  left:-18%;
  top:8%;
}

.watercolor_fruit.three{
  width:60%;
  right:-30%;
  margin-top:65%;
}


.watercolor_fruit.four{
  width:62%;
  right:-20%;
  margin-top:68%;
}

.watercolor_bg.three{
  width:140%;
  left:-15%;
  margin-top:20%;
}

.watercolor_fruit.five{
  width:80%;
  left:0%;
  margin-top:45%;
}

.watercolor_bg.five{
  width:100%;
  right:-30%;
  margin-top:-40%;
}

  
}


@media(max-width:600px){

}


@media(max-width:450px){

}