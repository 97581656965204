/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');*/

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow/Barlow-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url("./fonts/Barlow/Barlow-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("./fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("./fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("./fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url("./fonts/Barlow_Semi_Condensed/BarlowSemiCondensed-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');


.App {
  font-family: 'Barlow', sans-serif;
  font-size:18px;
  font-weight:normal;
  color:#2A2D31;
  overflow-x:hidden;
  width:100%;
  /*transition: .8s color, .8s background;*/
}
/*
.App.scrolled{
  color:#2A2D31;
  background: #fff;
}*/


html {
  /*scroll-behavior:smooth;*/
  background: rgb(243,248,252); /* Old browsers */
  background: linear-gradient(to right, rgba(243,248,252,1) 0%,rgba(255,255,255,1) 50%,rgba(243,248,252,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.page-container{
  margin:auto;
  width:calc(100% - 240px);
  height:100%;
  display: block;
  position:relative;
  margin-top:0px;
  z-index:10;
  max-width:1300px;
}




/*************************************************/
/*************** SECTION ELEMENTS ****************/
/*************************************************/


.toggle_tab{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:auto;
  width:100%;
  max-width:400px;
  margin-top: 60px;
  position:relative;
  height:50px;
  line-height:50px;
}

.toggle_tab .tab{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 33.33%;
  flex: 1;
  box-sizing:border-box;
  position:relative;
  text-align:center;
  font-weight:600;
  font-size:1.3em;
  font-family: "Barlow Semi Condensed", sans-serif;
  cursor:pointer;
}

.toggle_tab .tab::after{
  content: '';
  width: 0px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #F9866D;
  transition: .3s all;
}

.toggle_tab .tab:hover::after{
  content: '';
  width: 14%;
  position: absolute;
  left: 43%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #222;
}

.toggle_tab .tab:hover{
  color:#222;
}

.toggle_tab .tab.active{
  color:#F9866D;
}

.toggle_tab .tab.active::after{
  content: '';
  width: 14%;
  position: absolute;
  left: 43%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #F9866D;
}









.col2{
  width:50%;
  float:left;
}

.farmer{
  width:100%;
  position:relative;
  display:flex;
}


.farmer .col2{
  vertical-align: middle;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex: 1;
}

#farmer_section .wrap{
  
}

.farmer .name{
  font-weight:600;
  font-size:1.5em;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom:10px;
}

.farmer img{
  box-sizing: border-box;
  width: 100%;
}

.farmer .col2{
  padding:7%;
  box-sizing:border-box;
}

.farmer .col2.img_outer{
  padding:30px;
}

.farmer .col2.text_outer{
  padding: 30px 5%;
}

.carousel_outer{
  width:100%;
  max-width:1100px;
  margin:auto;
  position:relative;
  margin-top:90px;
  margin-bottom:50px;
}

.carousel_outer .farmer{
  display:none;
  background:white;
  border: 1px solid #D6D6DE;
  border-radius:4px;
  white-space:pre-wrap;
  box-sizing:border-box;
  transition: .3s all;
}

.carousel_outer .farmer.active{
  display:flex;
  animation: fade-in .25s ease-out;
}

.carousel_outer .farmer.faded{
  opacity:0;
}

@keyframes fade-in {
  from {opacity:0;}
  to {opacity:1;}
}

.carousel_outer .left_arrow{
  position:absolute;
  height:100%;
  width:80px;
  left:-100px;
  background:url('./img/left_arrow.png') center center no-repeat;
  background-size: 20px auto;
  border-radius:4px;
  transition: .4s background;
}

.carousel_outer .right_arrow{
  position:absolute;
  height:100%;
  width:80px;
  right:-100px;
  background:url('./img/right_arrow.png') center center no-repeat;
  background-size: 20px auto;
  border-radius:4px;
  transition: .4s background;
}

.carousel_outer .left_arrow:hover, .carousel_outer .right_arrow:hover{
  background-color:rgba(0,0,0,.04);
  cursor:pointer;
}
/*
.carousel_outer .farmer{
  transform:translateX(-50px);
  opacity:0;
}

.carousel_outer .farmer{
  transform:translateX(50px);
  opacity:0;
}
*/






#clients{
  width:100%;
  height:400px;
  margin-top:150px;
  margin-bottom:0px;
}

#clients_title{
  text-align:center;
  font-size:1.5em;
  font-weight:600;
  margin-bottom:-20px;
}

#clients_logos{
  width:100%;
  height:100%;
  background:url('./img/client-logos-mobile.png') center center no-repeat;
  background:url('./img/client-logos-desktop2.png') center center no-repeat;
  background:url('./img/client-logos-desktop.png') center center no-repeat;
  background-size:contain;
  opacity:.6;
}













/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  .page-container{
    max-width:1000px;
  }

  .start_order_module .title{
    font-size:1.8em;
  }

  
}

@media(max-width:1200px){
  .page-container{
    left: 80px;
    right: 80px;
    margin: 0px!important;
    width: calc(100% - 160px);
  }

  .App {
    font-size:16px;
  }

  #clients_logos{
    background:url('./img/client-logos-mobile.png') center center no-repeat;
    background:url('./img/client-logos-desktop2.png') center center no-repeat;
    background-size:contain;
    opacity:.2;
  }

  #clients_title {
    margin-bottom: 0px;
  }

  #logo_text {
    top:32px;
  }


}

@media(max-width:1000px){


  .page-container{
    left: 60px;
    right: 60px;
    margin: 0px!important;
    width: calc(100% - 120px);
  }

  .start_order_module {
    width: 100%;
    margin: auto;
    max-width: 500px;
    margin-bottom: 50px;
    float: none;
  }

  #main_logo{
    left: -130px;
    margin-left: 46%;
  }

}

@media(max-width:800px){
  
  .page-container{
    left: 40px;
    right: 40px;
    margin: 0px!important;
    width: calc(100% - 80px);
  }

  #farmers_hero .farmer.active{
    overflow:hidden;
    display:block;
  }

  #founder_section, .worker_section{
    display:block;
    width:100%;
    max-width:400px;
    margin:auto;
    margin-top:70px;
    overflow: hidden;
  }

  #founder_section .col2, .worker_section .col2{
    width:100%;
  }

  #founder_section .col2:last-child, .worker_section .col2:last-child{
    padding-top:0px;
  }

  .farmer .col2{
    width:100%;
  }
  .farmer .col2.text_outer {
    padding: 30px 30px;
    padding-top:0px;
  }

  #farmer_section .farmer{
    overflow:hidden;
    display:block;
    margin-bottom:50px;
  }
  

  #clients_logos{
    background:url('./img/client-logos-mobile.png') center center no-repeat;
    background-size:contain;
    opacity:.6;
  }

  #clients_title {
    margin-bottom: 0px;
  }

  .section_title{
    font-size:1.8em;
    max-width:300px;
  }
  .section_title::before {
    top: -15px;
  }
  .section_title::after {
    bottom: -17px;
  }

  #order .toggle_tab, .toggle_tab{
    max-width:300px;
    margin-top:40px;
  }

  .title_subtext{
    top:-40px;
  }

  #main_logo{
    left: -149px;
    margin-left: 49%;
  }

  
  
}


@media(max-width:600px){

  #logo_img {
    width:90px;
  }

  #logo_text {
    top:25px;
    left: 95px;
  }

  #philosophy .row .col2{
    width:100%;
  }

  #philosophy .section_title .description {
    padding: 0px 0px;
  }

  .start_order_module{
    padding-left:40px;
    padding-right:40px;
  }

  .start_order_module .title{
    font-size: 1.8em;
  }

  .start_order_module .next, .order_module .next{
    font-size:1.2em;
  }

  .order_module{
    padding-left:60px;
    padding-right:60px;
  }

  .order_module .back_button {
    left: 10px;
  }

  .order_module .option {
    width: calc(50% - 10px);
    height: 80px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 40px;
  }

  .order_module .option:hover {
    margin:0px;
    margin-top:40px;
    margin-right: 10px;
  }

  .order_module .option[data-active="true"] .title .check {
      width: 15px;
  }

  .order_module .option .title {
    font-size:.9em;
  }

  .order_module .option .recommendation {
    top: -18px;
  }
}


@media(max-width:450px){

}