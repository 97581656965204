




#in_season{
  padding-bottom:100px;
}


.toggle_tab .dot{
  width:6px;
  height:6px;
  border-radius:6px;
  background:black;
  margin-top:12px;
  top:11px;
  position:absolute;
}
.toggle_tab .dot.one{
  left:33.33%;
  margin-left:-10px;
}
.toggle_tab .dot.two{
  right:33.33%;
  margin-right:-10px;
}

#in_season .intro_text{
  width:100%;
  max-width:480px;
  margin:auto;
  margin-top:30px;
}

#seasonal_chart{
  width:100%;
  max-width:800px;
  margin:auto;
  margin-top:100px;
  position:relative;
  display: flex;
}


.chart_columns{
  position:absolute;
  left:250px;
  top:0px;
  width:calc(100% - 250px);
  height:100%;
  border-left:1px solid #D6D6DE;
  box-sizing:border-box;
  z-index:100;
}

.chart_column{
  float:left;
  height:100%;
  width: 25%;
  box-sizing:border-box;
  position:relative;
  border-right:1px solid #D6D6DE;
}

.chart_column.active{
  border-color: #36363F;
}

.chart_columns .label{
  text-align:center;
  margin-top:-40px;
  font-weight:500;
  color:#C9C9D2;
  position:relative;
}

.chart_columns .label.active{
  color:#36363F;
  font-weight:600;
}

.chart_labels{
  width:250px;
  position:relative;
  float:left;
  box-sizing:border-box;
  font-size:1.4em;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight:400;
  z-index:0;
}

.chart_labels .label{
  height:50px;
  line-height:50px;
  position:relative;
}

.chart_labels .sub_label{
  position:absolute;
  top:-20px;
  font-size:.5em;
  font-weight:600;
  left:65px;
  font-family: "Barlow", sans-serif;
}

.chart_labels .label img{
  width:50px;
  margin-right:15px;
  display:inline-block;
  vertical-align:bottom;
}

.chart_bars{
  float:left;
  width:calc(100% - 250px);
  border-left:1px solid #D6D6DE;
  box-sizing:border-box;
  overflow:hidden;
  position:relative;
  z-index:0;
}

.chart_bars .bar, .chart_active_bars .bar{
  height:12px;
  margin-top:21px;
  margin-bottom:38px;
  width:100%;
  background:#D6D6DE;
}

.chart_active_bars{
  position:absolute;
  width:calc(25%);
  left:calc(25% - 250px);
  margin-left:250px;
  height:100%;
}

.chart_active_bars .bar{
  background:#36363F;
}


/***** CHART BAR STYLES *****/

.chart_bars .bar[data-start="0"]{
  margin-left:0%;
  padding-left:10px;
}
.chart_bars .bar[data-start="1"]{
  margin-left:10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="2"]{
  margin-left:calc(25% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="3"]{
  margin-left:calc(50% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="4"]{
  margin-left:calc(75% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.chart_bars .bar[data-length="1"]{
  width:calc(25% - 20px);
}
.chart_bars .bar[data-length="2"]{
  width:calc(50% - 20px);
}
.chart_bars .bar[data-length="3"]{
  width:calc(75% - 20px);
}
.chart_bars .bar[data-length="4"]{
  width:calc(100% - 20px);
}
.chart_bars .bar[data-length="5"]{
  width:calc(100% - 20px);
}

.chart_bars .bar[data-end="1"]{
  margin-right:calc(75% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="2"]{
  margin-right:calc(50% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="3"]{
  margin-right:calc(25% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="4"]{
  margin-right:10px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="5"]{
  margin-right:0px;
  padding-right:10px;
}




/***** CHART ACTIVE BAR STYLES *****/

.chart_active_bars .bar::after{
  content: '✔';
  color: #36363F;
  width: 28px;
  height: 28px;
  line-height: 24px;
  background: white;
  border: 2px solid #36363F;
  margin: auto;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 30px;
  top: -9px;
  box-sizing: border-box;
  font-size:.8em;
}

.chart_active_bars .bar[data-start="0"]{
  margin-left:0%;
  padding-left:10px;
}
.chart_active_bars .bar[data-start="1"]{
  margin-left:10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.chart_active_bars .bar{
  width:calc(100% - 20px);
}

.chart_active_bars .bar[data-end="1"]{
  margin-right:calc(50% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_active_bars .bar[data-end="2"]{
  margin-right:calc(25% - 10px);
  padding-right:10px;
}


@media(max-width:600px){

  .chart_active_bars .bar::after {
      width: 25px;
      height: 25px;
      line-height: 21px;
      top: -6px;
  }
  .chart_labels .label img {
    width: 40px;
    margin-right: 10px;
  }

}