@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



#foot_info{
  width:100%;
  text-align:center;
  padding-top:50px;
  padding-bottom:90px;
}


#contact .page-container{
  padding-top:100px;
  overflow: hidden;
}

#contact .Description{
  max-width:380px;
}

#contact .Description icon{
  position:relative;
  line-height:.5em;
}


.Contact-form{
  width:100%;
  max-width:500px;
  float:right;
  position:relative;
  top:-105px;
  padding-right:11%;
}


.Contact-form input{
  width:calc(50% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  color:#2F373F;
  height:60px;
  background:none;
  padding:10px 20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input::-webkit-input-placeholder, .Contact-form textarea::-webkit-input-placeholder{
  color:#bbb;
}

.Contact-form input:-ms-input-placeholder, .Contact-form textarea:-ms-input-placeholder{
  color:#bbb;
}

.Contact-form input::-ms-input-placeholder, .Contact-form textarea::-ms-input-placeholder{
  color:#bbb;
}

.Contact-form input::placeholder, .Contact-form textarea::placeholder{
  color:#bbb;
}

.Contact-form textarea{
  width:calc(100% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #2F373F;
  background:none;
  color:#222222;
  height:100px;
  padding:20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input[type="Submit"]{
  background:white;
  width:120px;
  border-radius:5px;
  height:50px;
  box-shadow: 1px 1px 0px #2F373F;
  float:right;
  transition: background-color .3s;
  cursor:pointer;
}

.Contact-form input[type="Submit"]:hover{
  background-color: #eee;
  color:#2F373F;
}


#hellopress_link{
  color:white;
  text-decoration:none;
  font-weight:600;
  display:inline-block;
  margin-top:18px;
  font-size:.9em;
  line-height:20px;
  padding:3px 12px 4px 12px;
  background:#446;
  border-radius:100px;
  margin-left:-16px;
}

#hellopress_link:hover{
  background:#0784f8;
}



@media(max-width:1400px){
  .Contact-form {
    top: -105px;
    padding-right: 0px;
  }
}


@media(max-width:1200px){
  .Contact-form {
    width:50%;
  }
  #contact .Description{
    width:45%;
  }
}


@media(max-width:800px){
  #contact .Description{
    width:100%;
    max-width:400px;
  }
  .Contact-form {
    width: 100%;
    max-width: 500px;
    position: relative;
    padding-right: 0;
    margin: auto;
    margin-top: 160px;
    float:none;
  }
}



#header{
  height:200px;
  z-index:1000;
  position:relative;
}

#header .page-container{
  z-index:1001;
  pointer-events:none;
}

#main_nav{
  position:absolute;
  width:100%;
  height:80px;
  padding-top:50px;
  z-index:1000;
  pointer-events:none;
  background:rgba(255,255,255,0);
  transition: .4s background, .4s box-shadow;
  border:none;
  outline:none;
  box-shadow:none;
  padding-bottom:5px;
  overflow-x:scroll;
  overflow-y:hidden;
}


#main_nav[data-scrolled="true"]{
  background:white;
  transform: translateY(-50px);
  box-shadow:0px 2px 20px rgba(0,0,50,.03);
  position:fixed;
  z-index:1000000000;
  border-bottom: 1px solid #eaeaea;
}

.nav_inner{
  
}

#main_nav .nav-item{
  float:right;
  padding:0px 20px;
  height:80px;
  line-height:80px;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-weight:400;
  font-size:1.2em;
  pointer-events:auto;
  transition: .3s all;
  cursor:pointer;
  position:relative;
}
/*
#main_nav .nav-item:hover{
  color:#F9866D;
}*/

#main_nav .nav-item::after{
  content: '';
  width: 0px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #F9866D;
  transition: .3s all;
}

#main_nav .nav-item:hover::after{
  content: '';
  width: 20%;
  position: absolute;
  left: 40%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #222;
}

#main_nav .nav-item.active{
  color:#F9866D;
}

#main_nav .nav-item.active::after{
  content: '';
  width: 20%;
  position: absolute;
  left: 40%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #F9866D;
}



#main_logo{
  top:40px;
  position:absolute;
  font-family:"Barlow Semi Condensed", sans-serif;
  left:-15px;
}

#logo_img{
  width:105px;
}

#logo_text{
  position:absolute;
  top:30px;
  left:105px;
  width:300px;
}

#logo_name{
  font-size:2.2em;
  font-weight:Lighter;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-family: 'Caveat', cursive;
}

#logo_tag{
  font-weight:600;
  margin-top:2px;
  display:none;
}







/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){

  
}

@media(max-width:1200px){

  #main_nav[data-scrolled="true"]{
    padding-top:50px;
  }

  

}

@media(max-width:1000px){

  #main_nav{
    padding-top:120px;
    transition:none;
  }

  #main_nav[data-scrolled="true"]{
    box-shadow:none;
  }

  #nav_inner{
    margin:auto;
    max-width:510px;
  }

}

@media(max-width:800px){
  
  #main_nav .nav-item{
    padding:0px 10px;
    font-size:1em;
  }
  #main_nav #nav_inner{
    max-width: 360px;
    margin: auto;
  }
}
@media(max-width:600px){
  #main_nav .nav-item.contact{
    display:none;
  }

  #main_nav #nav_inner{
    max-width: 300px;
    margin: auto;
  }
  

}


@media(max-width:450px){

}


#hero{
  position:relative;
  height:calc(80vh);
  min-height:600px;
}

#hero_fruit{
  width:88%;
  right:-335px;
  position:absolute;
  margin-top:10px;
}

#hero_text{
  position:absolute;
  left:-80px;
  top:95px;
  z-index:1000;
  text-align:center;
}

#hero_text .one{
  color:#FAB686;
}
#hero_text .two{
  color:#FFA178;
}
#hero_text .three{
  color:#F9866D;
}

#hero_maintext{
  font-weight:700;
  font-size:5em;
  font-family:"Barlow Semi Condensed", sans-serif;
  line-height:1.3em;
  text-shadow: 0px 0px 100px white;
}

#hero_maintext::before {
  content: "";
  top:-25px;
  left:28%;
  right:28%;
  border-top:2px solid #F6C995;
  position:absolute;
}

#hero_subtext{
  position:absolute;
  bottom:-52px;
  padding:5px;
  background:white;
  width:30%;
  left:35%;
  text-align:center;
  font-size:1.3em;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-weight:800;
  color:#F97765;
}

#hero_maintext::after {
  content: "";
  bottom:-35px;
  left:28%;
  right:28%;
  border-bottom:2px solid #F97765;
  position:absolute;
}



#typewriter{
  display:inline-block;
  height:1.2em;
  vertical-align:top;
  overflow:hidden;
  text-align:center;
}

.word{
  display:block;
}



#type_inner{
  -webkit-animation: example 10s ease-in-out 2s infinite;
          animation: example 10s ease-in-out 2s infinite;
}
/*
#typewriter .word{
  animation: example 5s ease-in-out 0s infinite;
}*/
/*
@keyframes example {
  0% {transform: translateY(0em);}
  4% {transform: translateY(-1.3em);}
  12% {transform: translateY(-1.3em);}
  16% {transform: translateY(-2.6em);}
  24% {transform: translateY(-2.6em);}
  28% {transform: translateY(-3.9em);}
  36% {transform: translateY(-3.9em);}
  40% {transform: translateY(-5.2em);}
  48% {transform: translateY(-5.2em);}
  52% {transform: translateY(-6.5em);}
  60% {transform: translateY(-6.5em);}
  64% {transform: translateY(-7.8em);}
  72% {transform: translateY(-7.8em);}
  76% {transform: translateY(-9.1em);}
  84% {transform: translateY(-9.1em);}
  88% {transform: translateY(-10.4em);}
  96% {transform: translateY(-10.4em);}
  100% {transform: translateY(-10.4em);}
}*/

@-webkit-keyframes example {
  0% {transform: translateY(0em);}
  8% {transform: translateY(-1.3em);}
  20% {transform: translateY(-1.3em);}
  28% {transform: translateY(-2.6em);}
  40% {transform: translateY(-2.6em);}
  48% {transform: translateY(-3.9em);}
  60% {transform: translateY(-3.9em);}
  68% {transform: translateY(-5.2em);}
  80% {transform: translateY(-5.2em);}
  88% {transform: translateY(-6.5em);}
  100% {transform: translateY(-6.5em);}
}

@keyframes example {
  0% {transform: translateY(0em);}
  8% {transform: translateY(-1.3em);}
  20% {transform: translateY(-1.3em);}
  28% {transform: translateY(-2.6em);}
  40% {transform: translateY(-2.6em);}
  48% {transform: translateY(-3.9em);}
  60% {transform: translateY(-3.9em);}
  68% {transform: translateY(-5.2em);}
  80% {transform: translateY(-5.2em);}
  88% {transform: translateY(-6.5em);}
  100% {transform: translateY(-6.5em);}
}

@-webkit-keyframes example2 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}

@keyframes example2 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}

@-webkit-keyframes example3 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}

@keyframes example3 {
  0% {transform: translateY(-1.3em);}
  10% {transform: translateY(-2.6em);}
  90% {transform: translateY(-2.6em);}
  100% {transform: translateY(-3.9em);}
}






#how_it_works{
  padding-top:20px;
}

.section_title{
  font-weight:800;
  font-size:2.4em;
  font-family:"Barlow Semi Condensed", sans-serif;
  line-height:1.3em;
  width:100%;
  max-width:500px;
  text-align:center;
  position:relative;
  margin:auto;
  margin-top:100px;
  z-index: 100;
}

.section_title::before {
  content: "";
  top:-25px;
  left:20%;
  right:20%;
  border-top:2px solid #222222;
  position:absolute;
}

.title_subtext{
  position: absolute;
  top: -60px;
  padding: 5px;
  background: white;
  width: 20%;
  left: 40%;
  text-align: center;
  font-size: .53em;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 600;
  color: #222222;
  z-index: 100;
}

.section_title::after {
  content: "";
  bottom:-27px;
  left:20%;
  right:20%;
  border-bottom:2px solid #222222;
  position:absolute;
}







.section_body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top:60px;
  margin-bottom: 100px;
}

.column_body{
  position:relative;
  z-index:10;
}

.col3 .col{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 33.33%;
  flex: 1 1;
  box-sizing:border-box;
  padding:40px;
  position:relative;
}

.head_image{
  height:270px;
  margin:0px auto;
  padding-bottom:40px;
  z-index:1;
  mix-blend-mode: darken;
}

#how_it_works .watercolor{
  position:absolute;
  z-index:0;
}
#how_it_works .watercolor.one{
  width:160%;
  top:-120px;
  left:-45%;
}
#how_it_works .watercolor.two{
  width:120%;
  top:40px;
  left:-10%;
}
#how_it_works .watercolor.three{
  width:190%;
  top:-180px;
  right:-55%;
}


.column_body>.title{
  font-size:1.35em;
  font-weight:600;
  text-align:center;
  margin-bottom:15px;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.list .title{
  font-weight:bold;
  font-family: 'Caveat', cursive;
  font-size:1.4em;
  margin-top:15px;
  margin-bottom:8px;
}

.list .title>img{
  display:inline-block;
  vertical-align: bottom;
  width:36px;
  margin-right:5px;
  margin-left:-6px;
  margin-bottom:0px;
}

.list .list_item{
  padding-bottom:5px;
}







/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/



  

@media(max-width:1400px){
  .head_image{
    height:200px;
  }
  #how_it_works .col3 .col{
    padding:30px;
  }

  #hero_text {
    max-width: 600px;
  }
  #hero_maintext{
    font-size:4.2em;
    left:40px;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
  }
  #how_it_works{
    margin-top:-80px;
  }
}

@media(max-width:1200px){
  .column_body>.title {
    text-align:left;
  }
  .head_image{
    height:160px;
  }

  #hero{
    height:65vh;
  }
  #hero_text{
    margin-left:60px;
  }
  #how_it_works{
    margin-top:30px;
  }
  #hero_fruit{
    right:-250px;
  }
  .list .title>img{
    margin-bottom:-3px;
  }
}

@media(max-width:1000px){
  .head_image{
    height:140px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 120px);
  }
  #hero_text {
    position: relative;
    left: unset;
    top: 80px;
    margin: auto;
    text-align: center;
    max-width: 550px;
  }
  #hero_maintext{
    font-size:4em;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
  }
  #hero_fruit{
    width:100%;
    margin-top:160px;
    margin-right:-30px;
    right:0px;
  }
  #how_it_works{
    margin-top:25%;
  }

  
}

@media(max-width:800px){
  .head_image{
    height:200px;
  }
  .col3{
    display:block;
  }
  .col3>.col{
    width:100%;
  }
  #seasonal_chart .chart_labels{
    width:200px;
    font-size:1.2em;
  }
  #seasonal_chart .chart_bars{
    width: calc(100% - 200px);
  }
  #seasonal_chart .chart_columns{
    width: calc(100% - 200px);
    left:200px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 140px);
  }
  
  
}

@media(max-width:600px){

  #how_it_works{
    margin-top:-10%;
  }

  .section_body.col3 .col{
    padding:30px 0px;
  }

  #how_it_works .watercolor{
    opacity:.7;
  }

  #how_it_works .watercolor.one{
    width: 140%;
    top: -40px;
    left: -20%;
  }

  #how_it_works .watercolor.three{
    width: 180%;
    top: -55px;
    right: -35%;
  }

  #hero{
    height: 65vh;
  }
  #hero_maintext{
    font-size:2.35em;
  }
  #typewriter #type_inner span.word{
    line-height:1.30em;
  }
  #hero_subtext{
    font-size:1em;
    bottom:-47px;
    left:32%;
    width:33%;
  }
  #hero_text {
    position: relative;
    left: unset;
    top: 60px;
    margin: auto;
    text-align: center;
    max-width: 300px;
  }
  #hero_fruit{
    width:100%;
    margin-top:160px;
    margin-right:-10px;
    right:0px;
  }

  #seasonal_chart .chart_labels{
    width:160px;
    font-size:1.2em;
  }
  #seasonal_chart .chart_bars{
    width: calc(100% - 160px);
  }
  #seasonal_chart .chart_columns{
    width: calc(100% - 160px);
    left:160px;
  }
  #farmers_hero .carousel_outer{
    width: calc(100% - 40px);
  }

  #farmers_hero .carousel_outer .farmer{
    background:none;
    border:none;
  }

  #farmers_hero .farmer .col2.img_outer {
    padding:10px;
    padding-bottom:30px;
  }

  #farmers_hero .farmer .col2.text_outer {
    padding:10px;
  }

  #farmers_hero .carousel_outer .left_arrow{
    width: 50px;
    left: -55px;
  }
  #farmers_hero .carousel_outer .right_arrow{
    width: 50px;
    right: -55px;
  }
}

@media(max-width:450px){

}





#in_season{
  padding-bottom:100px;
}


.toggle_tab .dot{
  width:6px;
  height:6px;
  border-radius:6px;
  background:black;
  margin-top:12px;
  top:11px;
  position:absolute;
}
.toggle_tab .dot.one{
  left:33.33%;
  margin-left:-10px;
}
.toggle_tab .dot.two{
  right:33.33%;
  margin-right:-10px;
}

#in_season .intro_text{
  width:100%;
  max-width:480px;
  margin:auto;
  margin-top:30px;
}

#seasonal_chart{
  width:100%;
  max-width:800px;
  margin:auto;
  margin-top:100px;
  position:relative;
  display: flex;
}


.chart_columns{
  position:absolute;
  left:250px;
  top:0px;
  width:calc(100% - 250px);
  height:100%;
  border-left:1px solid #D6D6DE;
  box-sizing:border-box;
  z-index:100;
}

.chart_column{
  float:left;
  height:100%;
  width: 25%;
  box-sizing:border-box;
  position:relative;
  border-right:1px solid #D6D6DE;
}

.chart_column.active{
  border-color: #36363F;
}

.chart_columns .label{
  text-align:center;
  margin-top:-40px;
  font-weight:500;
  color:#C9C9D2;
  position:relative;
}

.chart_columns .label.active{
  color:#36363F;
  font-weight:600;
}

.chart_labels{
  width:250px;
  position:relative;
  float:left;
  box-sizing:border-box;
  font-size:1.4em;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight:400;
  z-index:0;
}

.chart_labels .label{
  height:50px;
  line-height:50px;
  position:relative;
}

.chart_labels .sub_label{
  position:absolute;
  top:-20px;
  font-size:.5em;
  font-weight:600;
  left:65px;
  font-family: "Barlow", sans-serif;
}

.chart_labels .label img{
  width:50px;
  margin-right:15px;
  display:inline-block;
  vertical-align:bottom;
}

.chart_bars{
  float:left;
  width:calc(100% - 250px);
  border-left:1px solid #D6D6DE;
  box-sizing:border-box;
  overflow:hidden;
  position:relative;
  z-index:0;
}

.chart_bars .bar, .chart_active_bars .bar{
  height:12px;
  margin-top:21px;
  margin-bottom:38px;
  width:100%;
  background:#D6D6DE;
}

.chart_active_bars{
  position:absolute;
  width:calc(25%);
  left:calc(25% - 250px);
  margin-left:250px;
  height:100%;
}

.chart_active_bars .bar{
  background:#36363F;
}


/***** CHART BAR STYLES *****/

.chart_bars .bar[data-start="0"]{
  margin-left:0%;
  padding-left:10px;
}
.chart_bars .bar[data-start="1"]{
  margin-left:10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="2"]{
  margin-left:calc(25% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="3"]{
  margin-left:calc(50% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.chart_bars .bar[data-start="4"]{
  margin-left:calc(75% + 10px);
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.chart_bars .bar[data-length="1"]{
  width:calc(25% - 20px);
}
.chart_bars .bar[data-length="2"]{
  width:calc(50% - 20px);
}
.chart_bars .bar[data-length="3"]{
  width:calc(75% - 20px);
}
.chart_bars .bar[data-length="4"]{
  width:calc(100% - 20px);
}
.chart_bars .bar[data-length="5"]{
  width:calc(100% - 20px);
}

.chart_bars .bar[data-end="1"]{
  margin-right:calc(75% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="2"]{
  margin-right:calc(50% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="3"]{
  margin-right:calc(25% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="4"]{
  margin-right:10px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_bars .bar[data-end="5"]{
  margin-right:0px;
  padding-right:10px;
}




/***** CHART ACTIVE BAR STYLES *****/

.chart_active_bars .bar::after{
  content: '✔';
  color: #36363F;
  width: 28px;
  height: 28px;
  line-height: 24px;
  background: white;
  border: 2px solid #36363F;
  margin: auto;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 30px;
  top: -9px;
  box-sizing: border-box;
  font-size:.8em;
}

.chart_active_bars .bar[data-start="0"]{
  margin-left:0%;
  padding-left:10px;
}
.chart_active_bars .bar[data-start="1"]{
  margin-left:10px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.chart_active_bars .bar{
  width:calc(100% - 20px);
}

.chart_active_bars .bar[data-end="1"]{
  margin-right:calc(50% - 10px);
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
.chart_active_bars .bar[data-end="2"]{
  margin-right:calc(25% - 10px);
  padding-right:10px;
}


@media(max-width:600px){

  .chart_active_bars .bar::after {
      width: 25px;
      height: 25px;
      line-height: 21px;
      top: -6px;
  }
  .chart_labels .label img {
    width: 40px;
    margin-right: 10px;
  }

}



#order_main_module .page-container{
  padding-top:100px;
}


#order_hero{
  width:100%;
  min-height:900px;
  background-size:cover;
  margin-top:100px;
  margin-bottom:160px;
}

.start_order_module{
  width: 40%;
  margin: 0px 5%;
  background:white;
  box-sizing:border-box;
  padding:60px;
  float:left;
  position:relative;
  padding-bottom:120px;
  border-radius:4px;
  box-shadow:0px 2px 20px rgba(0,0,0,.08);
  color:#3E242C;
  transition: .5s opacity;
}

.start_order_module .title{
  font-size:2.2em;
  font-weight:700;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding-bottom:20px;
  margin-top:-10px;
}

.start_order_module .next, .order_module .next{
  position:absolute;
  bottom:30px;
  right:-15px;
  background: #3E242C;
  border-radius:4px;
  padding:15px 25px;
  color:white;
  font-size:1.4em;
  cursor:pointer;
  transition: .3s background;
}

.order_module .next.disabled{
  background-color:#B3B3C0!important;
  pointer-events:none;
}

.start_order_module .next:hover, .order_module .next:hover{
  background:#5F345F;
}

.order_module.full .next{
  bottom:unset;
  top:50px;
}

.order_module[data-faded="true"], .start_order_module[data-faded="true"]{
  opacity:0;
}



.order_module{
  width: 100%;
  margin: auto;
  background:white;
  box-sizing:border-box;
  padding:60px 80px;
  position:relative;
  padding-bottom:120px;
  border-radius:4px;
  box-shadow:0px 2px 20px rgba(0,0,0,.08);
  color:#3E242C;
  transition: .5s opacity;
}

.order_module.small{
  max-width:680px;
}

.order_module.full{
  max-width:1100px;
  min-height:600px;
}

.order_module.full .description{
  margin-top:40px;
}

.order_module .question{
  font-size:1.4em;
  font-weight:600;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.order_module .back_button{
  position:absolute;
  top:49px;
  left:20px;
  width:15px;
  padding:15px;
  border-radius:4px;
}

.order_module .back_button:hover{
  background:#F2F4F5;
}

.order_module .description{
  width:100%;
  position:relative;
  max-width:400px;
}

.order_module .main_input{
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
  line-height:30px;
  background:#F2F4F5;
  border-radius:4px;
  border:none;
  padding:10px 20px;
  outline:none;
  font-size:1.1em;
  box-sizing:border-box;
  height:50px;
  position:relative;
}

.order_module #agreement{
  height: 20px;
  width: 20px;
  vertical-align: bottom;
  position: relative;
  margin-bottom: -1px;
}

.order_module #agreement_label{
  margin-top: -15px;
  height: 25px;
  display: inline;
}

.order_module input:nth-of-type(2){
  margin-top:10px;
}

.order_module input:nth-of-type(3){
  margin-top:10px;
}

.order_module input:nth-of-type(1){
  margin-top:30px;
}

.order_module select.main_input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
}

.order_module .main_input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B3B3C0;
  opacity: 1; /* Firefox */
}

.order_module .main_input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B3B3C0;
  opacity: 1; /* Firefox */
}

.order_module .main_input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B3B3C0;
  opacity: 1; /* Firefox */
}

.order_module .main_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B3B3C0;
  opacity: 1; /* Firefox */
}

.order_module .options{
  margin-top:20px;
  max-width:600px;
  overflow:hidden;
  padding:1px;
  padding-bottom:40px;
}

.order_module .option{
  width:140px;
  height:80px;
  margin-right:30px;
  margin-top:40px;
  border: 1px solid black;
  border-radius:4px;
  box-shadow: 5px 5px 0px #F7AA87;
  float:left;
  position:relative;
  text-align:center;
  font-family: "Barlow Semi Condensed", sans-serif;
  cursor:pointer;
  background:white;
  transition: .3s background, .3s color;
}

.order_module .option .title .check{
  opacity:0;
  width:0px;
  transition: .3s width, .3s opacity;
  display:inline-block;
  text-align:left;
}

.order_module .option[data-active="true"] .title .check{
  width:25px;
  opacity:1
}

.order_module .option:hover{
  margin-left:-1px;
  margin-top:39px;
  margin-right:31px;
  margin-bottom:1px;
  box-shadow: 6px 6px 0px #F7AA87;
}

.order_module .option:active{
  margin-left:1px;
  margin-top:41px;
  margin-right:29px;
  margin-bottom:-1px;
  box-shadow: 4px 4px 0px #F7AA87;
}

.order_module .option[data-active="true"]{
  background:black;
  color:white;
}

.order_module .option .title{
  margin-top:20px;
  font-weight:600;
  font-size:1.1em;
}

.order_module .option .subtitle{
  font-size:.7em;
  margin-top:3px;
}

.order_module .option .recommendation{
  color:black;
  position:absolute;
  top:-13px;
  padding:3px 8px;
  border: 1px solid black;
  border-radius:4px;
  font-size:.7em;
  background:#F7AA87;
  right: 16%;
  left: 16%;
  display:none;
}

.order_module .option .recommendation[data-active="true"]{
  display:block;
}

.order_module *{
  z-index:1;
}

.order_module img.box_options{
  width:100%;
  position:absolute;
  bottom:0px;
  left:0px;
  z-index:0;
}

.order_module img.box{
  width: 60%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index:0;
}

#payment_order_module{
  padding-bottom:80px;
}

#payment_order_module .description{
  margin-top:30px;
  margin-bottom:20px;
}

#paypal-button-container{
  max-width:300px;
  margin:auto;
  margin-top:60px;
}

#order_complete_module .description{
  margin-top:20px;
}

#order_complete_module{
  max-width: 540px;
}

#order_complete_module #gif{
  background: url(/static/media/avocado_yay.2a4dc12f.gif) center bottom no-repeat;
  background-size:contain;
  background-color:#F5F4E3;
  height:160px;
  width:100%;
  margin-top:20px;
}

#promo_code{
  margin-top:10px;
  text-transform:uppercase;
}

.valid_promo{
  color:#78D700;
  font-weight:700;
}

.invalid_promo{
  color:#F96D6D;
  font-weight:700;
}

#about_overview{
  margin-top:0px;
  margin-bottom:400px;
}

.about_card{
  width:calc(30% + 300px);
  box-sizing:border-box;
  padding:50px;
  background:white;
  border: 1px solid#D6D6DE;
  border-radius:4px;
  margin-top:30%;
  position:relative;
  z-index:10;
}

.about_card.right{
  left:calc(70% - 300px);
}

.list-item{
  display:inline-block;
  margin-top:15px;
  margin-left:25px;
}

.about_card .title{
  font-size:2.4em;
  position:relative;
  margin:auto;
  margin-top:-140px;
  height:0px;
  text-align:center;
  font-weight:700;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.about_card .description{
  position:relative;
  margin:auto;
  margin-top:150px;
}

.about_card .link{
  position:relative;
  margin:auto;
  margin-top:25px;
  text-align:right;
  font-size:1.2em;
  color:#F9866D;
  font-weight:600;
  cursor:pointer;
}

.about_card_image{
  position:relative;
  width:65%;
  left:30%;
  margin-top:-25%;
  z-index:0;
  border-radius: 4px;
  z-index:9;
}

.about_card_image.left{
  left:0px;
}


.watercolor_bg{
  position:absolute;
  mix-blend-mode: darken;
  z-index:0;
}

.watercolor_fruit{
  position:absolute;
  z-index:1;
  opacity:.8;
}

.watercolor_bg.one{
  width:100%;
  right:-30%;
  top:-12%;
}

.watercolor_fruit.one{
  width:40%;
  right:-10%;
  top:-10%;
}

.watercolor_bg.two{
  width:100%;
  left:-30%;
  top:11%;
}

.watercolor_fruit.two{
  width:30%;
  left:-10%;
  top:20%;
}

.watercolor_fruit.three{
  width:60%;
  right:-30%;
  margin-top:10%;
}


.watercolor_fruit.four{
  width:50%;
  right:-20%;
  margin-top:20%;
}

.watercolor_bg.three{
  width:85%;
  right:-15%;
  margin-top:85%;
}

.watercolor_fruit.five{
  width:55%;
  left:0%;
  margin-top:-8%;
}

.watercolor_bg.five{
  width:100%;
  right:-30%;
  margin-top:-20%;
}





/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){

}

@media(max-width:1200px){

}

@media(max-width:1000px){



}

@media(max-width:800px){


  #about_overview{
    margin-top:200px;
    margin-bottom:200px;
  }

  .about_card .title{
    font-size:2em;
    margin-top:-40px;
  }

  .about_card .description{
    margin-top:80px;
  }

  .about_card{
    width:100%;
    background:none;
    border:0px;
    padding:30px;
    padding-bottom:50px;
  }

  .about_card.right{
    left:0px;
  }


  .about_card_image{
    width:100%;
    left:0px;
    margin-bottom:100px;
    margin-top:0px;
  }

  .about_card_image.left{
    left:0px;
  }


  .watercolor_fruit{
    opacity:.8;
  }

.watercolor_bg.one{
  width:120%;
  right:-30%;
  top:-6%;
}

.watercolor_fruit.one{
  width:40%;
  right:-10%;
  top:-4%;
}

.watercolor_bg.two{
  width:140%;
  left:-40%;
  top:200px;
}

.watercolor_fruit.two{
  width:30%;
  left:-18%;
  top:8%;
}

.watercolor_fruit.three{
  width:60%;
  right:-30%;
  margin-top:65%;
}


.watercolor_fruit.four{
  width:62%;
  right:-20%;
  margin-top:68%;
}

.watercolor_bg.three{
  width:140%;
  left:-15%;
  margin-top:20%;
}

.watercolor_fruit.five{
  width:80%;
  left:0%;
  margin-top:45%;
}

.watercolor_bg.five{
  width:100%;
  right:-30%;
  margin-top:-40%;
}

  
}


@media(max-width:600px){

}


@media(max-width:450px){

}

#order_main_module{
  width:100%;
  min-height:900px;
  background-size:cover;
  margin-top:50px;
  margin-bottom:140px;
  padding-bottom:60px;

}

#order .section_title::before, #order .section_title::after {
  left: 30%;
  right: 30%;
}





#order .toggle_tab{
  max-width:400px;
  padding-left:15px;
}

#order .toggle_tab .dot{
  top:11px;
  left:52.5%;
}

#order .toggle_tab .tab{
  width:50%;
  font-size:1.4em!important;
}



#about .section_title::before, #about .section_title::after {
  left: 30%;
  right: 30%;
}

#founder_section{
  margin-top:50px;
  display: flex;
}

.worker_section{
  margin-top:50px;
  display: flex;
}

#about .col2{
  vertical-align: middle;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex: 1 1;
  padding:7%;
  box-sizing:border-box;
}

#founder_section .wrap{
}

.col2 .name{
  font-weight:600;
  font-size:1.5em;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom:20px;
  padding-right:30px;
}

.col2 img{
  box-sizing: border-box;
  width: 100%;
}

#philosophy .col2 .name{
  text-align:center;
}

#philosophy .section_title::before, #philosophy .section_title::after{
  display:none;
}

#philosophy .section_title img{
  width:120px;
}

#philosophy .section_title .description{
  font-size:18px;
  font-family:'Barlow',sans-serif;
  line-height:22px;
  font-weight:400;
  margin-top:15px;
  text-align:left;
}

#philosophy .section_title .title_text{
  margin-top:10px;
}

#philosophy .col2{
  padding:4% 7%;
}

#philosophy .section_body .name>img{
  display:inline-block;
  vertical-align: bottom;
  width:40px;
  margin-right:10px;
  margin-bottom:-5px;
}




#farmer_section{
    margin-top:50px;
}

/*@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');*/

@font-face {
  font-family: "Barlow";
  src: url(/static/media/Barlow-Regular.2cce8c80.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow";
  src: url(/static/media/Barlow-Bold.7130fdb0.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url(/static/media/BarlowSemiCondensed-Light.196e783a.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url(/static/media/BarlowSemiCondensed-Regular.cfe0d977.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url(/static/media/BarlowSemiCondensed-Bold.396b59d3.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Barlow Semi Condensed";
  src: url(/static/media/BarlowSemiCondensed-ExtraBold.0181d1e5.ttf) format("truetype"); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}


.App {
  font-family: 'Barlow', sans-serif;
  font-size:18px;
  font-weight:normal;
  color:#2A2D31;
  overflow-x:hidden;
  width:100%;
  /*transition: .8s color, .8s background;*/
}
/*
.App.scrolled{
  color:#2A2D31;
  background: #fff;
}*/


html {
  /*scroll-behavior:smooth;*/
  background: rgb(243,248,252); /* Old browsers */
  background: linear-gradient(to right, rgba(243,248,252,1) 0%,rgba(255,255,255,1) 50%,rgba(243,248,252,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.page-container{
  margin:auto;
  width:calc(100% - 240px);
  height:100%;
  display: block;
  position:relative;
  margin-top:0px;
  z-index:10;
  max-width:1300px;
}




/*************************************************/
/*************** SECTION ELEMENTS ****************/
/*************************************************/


.toggle_tab{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin:auto;
  width:100%;
  max-width:400px;
  margin-top: 60px;
  position:relative;
  height:50px;
  line-height:50px;
}

.toggle_tab .tab{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 33.33%;
  flex: 1 1;
  box-sizing:border-box;
  position:relative;
  text-align:center;
  font-weight:600;
  font-size:1.3em;
  font-family: "Barlow Semi Condensed", sans-serif;
  cursor:pointer;
}

.toggle_tab .tab::after{
  content: '';
  width: 0px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #F9866D;
  transition: .3s all;
}

.toggle_tab .tab:hover::after{
  content: '';
  width: 14%;
  position: absolute;
  left: 43%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #222;
}

.toggle_tab .tab:hover{
  color:#222;
}

.toggle_tab .tab.active{
  color:#F9866D;
}

.toggle_tab .tab.active::after{
  content: '';
  width: 14%;
  position: absolute;
  left: 43%;
  margin-left: 0px;
  bottom: 0px;
  border-bottom: 1px solid #F9866D;
}









.col2{
  width:50%;
  float:left;
}

.farmer{
  width:100%;
  position:relative;
  display:flex;
}


.farmer .col2{
  vertical-align: middle;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  flex: 1 1;
}

#farmer_section .wrap{
  
}

.farmer .name{
  font-weight:600;
  font-size:1.5em;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom:10px;
}

.farmer img{
  box-sizing: border-box;
  width: 100%;
}

.farmer .col2{
  padding:7%;
  box-sizing:border-box;
}

.farmer .col2.img_outer{
  padding:30px;
}

.farmer .col2.text_outer{
  padding: 30px 5%;
}

.carousel_outer{
  width:100%;
  max-width:1100px;
  margin:auto;
  position:relative;
  margin-top:90px;
  margin-bottom:50px;
}

.carousel_outer .farmer{
  display:none;
  background:white;
  border: 1px solid #D6D6DE;
  border-radius:4px;
  white-space:pre-wrap;
  box-sizing:border-box;
  transition: .3s all;
}

.carousel_outer .farmer.active{
  display:flex;
  -webkit-animation: fade-in .25s ease-out;
          animation: fade-in .25s ease-out;
}

.carousel_outer .farmer.faded{
  opacity:0;
}

@-webkit-keyframes fade-in {
  from {opacity:0;}
  to {opacity:1;}
}

@keyframes fade-in {
  from {opacity:0;}
  to {opacity:1;}
}

.carousel_outer .left_arrow{
  position:absolute;
  height:100%;
  width:80px;
  left:-100px;
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAB4CAYAAABGtSQjAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANWSURBVHgB7d09ctNAGIDhjUnHDGMoGKAhDEMFhblAJklDyw3wDbiCwwk4Ai0dJwgBDoAL6FKYDiggDTMUwUYv2EYE/6yl/fm+b3krOdrGz6xGlrSaOFdQezs73d079x4v2nfBFRII4+2LL53b6t+8ctV9+PL5VX1/ERA1hN7sT+cxzEMsQJjvqmOYhliBMB8yw9hyRvNA+NNk0jcJsRGCmww7Z9/2zUE0QTgejU5NQTRF4JMZiDYIZAKiLQKphwiBQKohQiGQWoiQCKQSIjQCqYOIgUCqIGIhkBqImAikAiI2AomHSIFAoiFSIZBYiJQIJBIiNQKJg8iBQKIgciGQGIicCCQCIjcCZYeQgEBZIaQgUDYISQiUBUIaAiWHkIhASSGkIlAyCMkIlARCOgJFh9CAQFEhtCBQNAhNCBQFQhsCBYfQiEBBIbQiUDAIzQgUBEI7ArWGsIBArSCsIFBjCEsI1AjCGgJtDGERgTaCsIpA3hCWEcgLwjoCrYUoAYFWQpSCQEshSkKghRClIdA/ECUi0F8QpSLQHKJkBPoFUToCbf1H+F3Hua7z/6XNuK6z2PTQ6FWz4qyaFc5jVrhqVmxXs2Jo69CYbZSOce70WS7Ggh9UZWIs+YldHsaKi66yMNZchpeD4XFjpgwMz1t19jE2uHlrG2PD2/l2MRo84LGJ0fCRnz2MFg+BbWG0XBZgByPAQhEbGIGWDunHCLiYTDdG4OWFejEiLDjViRFpCbI+jIiL0nVhRH5NQQ9GghdXdGAkepVJPkYSCJKOkQyCJGMkhSCpGMkhSCJGFgiShpENgiRhZIUgKRjZIUgChggIyo0hBoJyYnScoPhSfLlqc+gxvAdahXd5MBi4tomaEbMazIyDvf7Dr21AREJQagyxEJQSQzQEpcIQD0EpMFRAUGwMUafPVdVOrW89hnNqPTp+9sL71KpmRsyazoyjavO+x3DvmaEOgmJgqISg0BhqISgkhmoICoWhHoJCYJiAoLYYZiCoDYYpCGqKYQ6CGmDsm/zvjqPTj99vXbrxfNIZP6g+Xl8z/Nq48+OTmmuNTZtemxy49dcmh29O3j81eWjUW3OYHL4+eTdgwzwELcGYI1AREFTD4H7GkzpCcYGxe/vuo0X7fgJV+UA2sEAk9AAAAABJRU5ErkJggg==) center center no-repeat;
  background-size: 20px auto;
  border-radius:4px;
  transition: .4s background;
}

.carousel_outer .right_arrow{
  position:absolute;
  height:100%;
  width:80px;
  right:-100px;
  background:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAAB4CAYAAABGtSQjAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM+SURBVHgB7d07ctNQGEBhXSk00DAUtGSGVEDhDYQJO2AJLIEdwA5YAuyELMFLCJQwAzR0ThwdJ844HsmWpfv4HzlF/FIKfyP5IUv3VlVHpy9ffTg7nj2tHFVv3/H25M2nEOqvV0eL754wwuYNENqLzxt3zevF0bvzi/m/ynh3EB0I61xgrCB2IKwzjxFOT15/DFX4MmBZ0xh1s3j0rb2cD1h2ZvkFdLVp8OR4ku3V2YD/Mblm3L1Yese49/bpGSNs3+EVI3Td6REj9D3gDSPsetATRti3gBeMvRDkAWMQBFnHGAxBljEOgiCrGAdDkEWMURBkDWM0BFnCmARBVjAmQ5AFjCgQpB0jGgRpxogKQVoxokOQRowkEKQNIxkEacJICkFaMJJDkAaMLBAkHSMbBEnGyApBUjGyQ5BEjCIQJA2jGARJwigKQVIwikOQBAwREFQaQwwElcQQBUGlMMRBUAkMkRCUG0MsBOXEEA1BuTDEQ1AODBUQlBpDDQSlxFAFQakw1EFQCgyVEBQbQy0ExcRQDUGxMNRDUAwMExA0FcMMBE3BMAVBYzHMQdAYjLoyGWv7cuCyy9XyBjeN43ZteNKuDWHA2rBs14b/7aZxYWvTGIvALUNvn+MRyMgHqmkIZOAj9nQEUv6lKw4CKf4aHg+BlO6YiYtACnfVxUcgZTtv0yCQot356RBIyQ88aRFIwU9+6RFI+I/AeRBI8GEB+RBI6IEieRFI4KFD+RFI2MFkZRBI0OGF5RBIyAGnZRFIwCHI5RGo8EHpMhCo4GkKchCo0IkrshCowKlM8hAo88ltMhEo4+mOchEo0wmwshEowynR8hEo8UnyOhAo4bAJehAo0UAauhAowdAq+hAo8mA7OhEo4vBLehEo0oBcuhEowhBt+hFo4qB9NhBowjCOdhBo5MCethBoxFCv9hDowMF/bSLQAcNB20WggQOE20agAUPG20egPZMI+ECgHdNK+EGgnolGfCFQx9Qz/hBoazIinwi0MT2VXwS6nbDMNwKFB4Sb6svm8XvvCNT8/Pt7/uLZczaRs/7FbCNQw58ff36d92PYR6BmfaUbwwcCNZs37mP4QeiNWWF5N6kcdQ0AnVFCKYBtTAAAAABJRU5ErkJggg==) center center no-repeat;
  background-size: 20px auto;
  border-radius:4px;
  transition: .4s background;
}

.carousel_outer .left_arrow:hover, .carousel_outer .right_arrow:hover{
  background-color:rgba(0,0,0,.04);
  cursor:pointer;
}
/*
.carousel_outer .farmer{
  transform:translateX(-50px);
  opacity:0;
}

.carousel_outer .farmer{
  transform:translateX(50px);
  opacity:0;
}
*/






#clients{
  width:100%;
  height:400px;
  margin-top:150px;
  margin-bottom:0px;
}

#clients_title{
  text-align:center;
  font-size:1.5em;
  font-weight:600;
  margin-bottom:-20px;
}

#clients_logos{
  width:100%;
  height:100%;
  background:url(/static/media/client-logos-mobile.bffe31b7.png) center center no-repeat;
  background:url(/static/media/client-logos-desktop2.a7df5a77.png) center center no-repeat;
  background:url(/static/media/client-logos-desktop.350a8ef1.png) center center no-repeat;
  background-size:contain;
  opacity:.6;
}













/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  .page-container{
    max-width:1000px;
  }

  .start_order_module .title{
    font-size:1.8em;
  }

  
}

@media(max-width:1200px){
  .page-container{
    left: 80px;
    right: 80px;
    margin: 0px!important;
    width: calc(100% - 160px);
  }

  .App {
    font-size:16px;
  }

  #clients_logos{
    background:url(/static/media/client-logos-mobile.bffe31b7.png) center center no-repeat;
    background:url(/static/media/client-logos-desktop2.a7df5a77.png) center center no-repeat;
    background-size:contain;
    opacity:.2;
  }

  #clients_title {
    margin-bottom: 0px;
  }

  #logo_text {
    top:32px;
  }


}

@media(max-width:1000px){


  .page-container{
    left: 60px;
    right: 60px;
    margin: 0px!important;
    width: calc(100% - 120px);
  }

  .start_order_module {
    width: 100%;
    margin: auto;
    max-width: 500px;
    margin-bottom: 50px;
    float: none;
  }

  #main_logo{
    left: -130px;
    margin-left: 46%;
  }

}

@media(max-width:800px){
  
  .page-container{
    left: 40px;
    right: 40px;
    margin: 0px!important;
    width: calc(100% - 80px);
  }

  #farmers_hero .farmer.active{
    overflow:hidden;
    display:block;
  }

  #founder_section, .worker_section{
    display:block;
    width:100%;
    max-width:400px;
    margin:auto;
    margin-top:70px;
    overflow: hidden;
  }

  #founder_section .col2, .worker_section .col2{
    width:100%;
  }

  #founder_section .col2:last-child, .worker_section .col2:last-child{
    padding-top:0px;
  }

  .farmer .col2{
    width:100%;
  }
  .farmer .col2.text_outer {
    padding: 30px 30px;
    padding-top:0px;
  }

  #farmer_section .farmer{
    overflow:hidden;
    display:block;
    margin-bottom:50px;
  }
  

  #clients_logos{
    background:url(/static/media/client-logos-mobile.bffe31b7.png) center center no-repeat;
    background-size:contain;
    opacity:.6;
  }

  #clients_title {
    margin-bottom: 0px;
  }

  .section_title{
    font-size:1.8em;
    max-width:300px;
  }
  .section_title::before {
    top: -15px;
  }
  .section_title::after {
    bottom: -17px;
  }

  #order .toggle_tab, .toggle_tab{
    max-width:300px;
    margin-top:40px;
  }

  .title_subtext{
    top:-40px;
  }

  #main_logo{
    left: -149px;
    margin-left: 49%;
  }

  
  
}


@media(max-width:600px){

  #logo_img {
    width:90px;
  }

  #logo_text {
    top:25px;
    left: 95px;
  }

  #philosophy .row .col2{
    width:100%;
  }

  #philosophy .section_title .description {
    padding: 0px 0px;
  }

  .start_order_module{
    padding-left:40px;
    padding-right:40px;
  }

  .start_order_module .title{
    font-size: 1.8em;
  }

  .start_order_module .next, .order_module .next{
    font-size:1.2em;
  }

  .order_module{
    padding-left:60px;
    padding-right:60px;
  }

  .order_module .back_button {
    left: 10px;
  }

  .order_module .option {
    width: calc(50% - 10px);
    height: 80px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 40px;
  }

  .order_module .option:hover {
    margin:0px;
    margin-top:40px;
    margin-right: 10px;
  }

  .order_module .option[data-active="true"] .title .check {
      width: 15px;
  }

  .order_module .option .title {
    font-size:.9em;
  }

  .order_module .option .recommendation {
    top: -18px;
  }
}


@media(max-width:450px){

}
