


#header{
  height:200px;
  z-index:1000;
  position:relative;
}

#header .page-container{
  z-index:1001;
  pointer-events:none;
}

#main_nav{
  position:absolute;
  width:100%;
  height:80px;
  padding-top:50px;
  z-index:1000;
  pointer-events:none;
  background:rgba(255,255,255,0);
  transition: .4s background, .4s box-shadow;
  border:none;
  outline:none;
  box-shadow:none;
  padding-bottom:5px;
  overflow-x:scroll;
  overflow-y:hidden;
}


#main_nav[data-scrolled="true"]{
  background:white;
  transform: translateY(-50px);
  box-shadow:0px 2px 20px rgba(0,0,50,.03);
  position:fixed;
  z-index:1000000000;
  border-bottom: 1px solid #eaeaea;
}

.nav_inner{
  
}

#main_nav .nav-item{
  float:right;
  padding:0px 20px;
  height:80px;
  line-height:80px;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-weight:400;
  font-size:1.2em;
  pointer-events:auto;
  transition: .3s all;
  cursor:pointer;
  position:relative;
}
/*
#main_nav .nav-item:hover{
  color:#F9866D;
}*/

#main_nav .nav-item::after{
  content: '';
  width: 0px;
  position: absolute;
  left: 50%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #F9866D;
  transition: .3s all;
}

#main_nav .nav-item:hover::after{
  content: '';
  width: 20%;
  position: absolute;
  left: 40%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #222;
}

#main_nav .nav-item.active{
  color:#F9866D;
}

#main_nav .nav-item.active::after{
  content: '';
  width: 20%;
  position: absolute;
  left: 40%;
  margin-left: 0px;
  bottom: 20px;
  border-bottom: 1px solid #F9866D;
}



#main_logo{
  top:40px;
  position:absolute;
  font-family:"Barlow Semi Condensed", sans-serif;
  left:-15px;
}

#logo_img{
  width:105px;
}

#logo_text{
  position:absolute;
  top:30px;
  left:105px;
  width:300px;
}

#logo_name{
  font-size:2.2em;
  font-weight:Lighter;
  font-family:"Barlow Semi Condensed", sans-serif;
  font-family: 'Caveat', cursive;
}

#logo_tag{
  font-weight:600;
  margin-top:2px;
  display:none;
}







/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){

  
}

@media(max-width:1200px){

  #main_nav[data-scrolled="true"]{
    padding-top:50px;
  }

  

}

@media(max-width:1000px){

  #main_nav{
    padding-top:120px;
    transition:none;
  }

  #main_nav[data-scrolled="true"]{
    box-shadow:none;
  }

  #nav_inner{
    margin:auto;
    max-width:510px;
  }

}

@media(max-width:800px){
  
  #main_nav .nav-item{
    padding:0px 10px;
    font-size:1em;
  }
  #main_nav #nav_inner{
    max-width: 360px;
    margin: auto;
  }
}
@media(max-width:600px){
  #main_nav .nav-item.contact{
    display:none;
  }

  #main_nav #nav_inner{
    max-width: 300px;
    margin: auto;
  }
  

}


@media(max-width:450px){

}