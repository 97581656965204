


#order_main_module .page-container{
  padding-top:100px;
}


#order_hero{
  width:100%;
  min-height:900px;
  background-size:cover;
  margin-top:100px;
  margin-bottom:160px;
}

.start_order_module{
  width: 40%;
  margin: 0px 5%;
  background:white;
  box-sizing:border-box;
  padding:60px;
  float:left;
  position:relative;
  padding-bottom:120px;
  border-radius:4px;
  box-shadow:0px 2px 20px rgba(0,0,0,.08);
  color:#3E242C;
  transition: .5s opacity;
}

.start_order_module .title{
  font-size:2.2em;
  font-weight:700;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding-bottom:20px;
  margin-top:-10px;
}

.start_order_module .next, .order_module .next{
  position:absolute;
  bottom:30px;
  right:-15px;
  background: #3E242C;
  border-radius:4px;
  padding:15px 25px;
  color:white;
  font-size:1.4em;
  cursor:pointer;
  transition: .3s background;
}

.order_module .next.disabled{
  background-color:#B3B3C0!important;
  pointer-events:none;
}

.start_order_module .next:hover, .order_module .next:hover{
  background:#5F345F;
}

.order_module.full .next{
  bottom:unset;
  top:50px;
}

.order_module[data-faded="true"], .start_order_module[data-faded="true"]{
  opacity:0;
}



.order_module{
  width: 100%;
  margin: auto;
  background:white;
  box-sizing:border-box;
  padding:60px 80px;
  position:relative;
  padding-bottom:120px;
  border-radius:4px;
  box-shadow:0px 2px 20px rgba(0,0,0,.08);
  color:#3E242C;
  transition: .5s opacity;
}

.order_module.small{
  max-width:680px;
}

.order_module.full{
  max-width:1100px;
  min-height:600px;
}

.order_module.full .description{
  margin-top:40px;
}

.order_module .question{
  font-size:1.4em;
  font-weight:600;
  font-family: "Barlow Semi Condensed", sans-serif;
}

.order_module .back_button{
  position:absolute;
  top:49px;
  left:20px;
  width:15px;
  padding:15px;
  border-radius:4px;
}

.order_module .back_button:hover{
  background:#F2F4F5;
}

.order_module .description{
  width:100%;
  position:relative;
  max-width:400px;
}

.order_module .main_input{
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
  line-height:30px;
  background:#F2F4F5;
  border-radius:4px;
  border:none;
  padding:10px 20px;
  outline:none;
  font-size:1.1em;
  box-sizing:border-box;
  height:50px;
  position:relative;
}

.order_module #agreement{
  height: 20px;
  width: 20px;
  vertical-align: bottom;
  position: relative;
  margin-bottom: -1px;
}

.order_module #agreement_label{
  margin-top: -15px;
  height: 25px;
  display: inline;
}

.order_module input:nth-of-type(2){
  margin-top:10px;
}

.order_module input:nth-of-type(3){
  margin-top:10px;
}

.order_module input:nth-of-type(1){
  margin-top:30px;
}

.order_module select.main_input{
  appearance:none;
}

.order_module .main_input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #B3B3C0;
  opacity: 1; /* Firefox */
}

.order_module .options{
  margin-top:20px;
  max-width:600px;
  overflow:hidden;
  padding:1px;
  padding-bottom:40px;
}

.order_module .option{
  width:140px;
  height:80px;
  margin-right:30px;
  margin-top:40px;
  border: 1px solid black;
  border-radius:4px;
  box-shadow: 5px 5px 0px #F7AA87;
  float:left;
  position:relative;
  text-align:center;
  font-family: "Barlow Semi Condensed", sans-serif;
  cursor:pointer;
  background:white;
  transition: .3s background, .3s color;
}

.order_module .option .title .check{
  opacity:0;
  width:0px;
  transition: .3s width, .3s opacity;
  display:inline-block;
  text-align:left;
}

.order_module .option[data-active="true"] .title .check{
  width:25px;
  opacity:1
}

.order_module .option:hover{
  margin-left:-1px;
  margin-top:39px;
  margin-right:31px;
  margin-bottom:1px;
  box-shadow: 6px 6px 0px #F7AA87;
}

.order_module .option:active{
  margin-left:1px;
  margin-top:41px;
  margin-right:29px;
  margin-bottom:-1px;
  box-shadow: 4px 4px 0px #F7AA87;
}

.order_module .option[data-active="true"]{
  background:black;
  color:white;
}

.order_module .option .title{
  margin-top:20px;
  font-weight:600;
  font-size:1.1em;
}

.order_module .option .subtitle{
  font-size:.7em;
  margin-top:3px;
}

.order_module .option .recommendation{
  color:black;
  position:absolute;
  top:-13px;
  padding:3px 8px;
  border: 1px solid black;
  border-radius:4px;
  font-size:.7em;
  background:#F7AA87;
  right: 16%;
  left: 16%;
  display:none;
}

.order_module .option .recommendation[data-active="true"]{
  display:block;
}

.order_module *{
  z-index:1;
}

.order_module img.box_options{
  width:100%;
  position:absolute;
  bottom:0px;
  left:0px;
  z-index:0;
}

.order_module img.box{
  width: 60%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index:0;
}

#payment_order_module{
  padding-bottom:80px;
}

#payment_order_module .description{
  margin-top:30px;
  margin-bottom:20px;
}

#paypal-button-container{
  max-width:300px;
  margin:auto;
  margin-top:60px;
}

#order_complete_module .description{
  margin-top:20px;
}

#order_complete_module{
  max-width: 540px;
}

#order_complete_module #gif{
  background: url('./img/avocado_yay.gif') center bottom no-repeat;
  background-size:contain;
  background-color:#F5F4E3;
  height:160px;
  width:100%;
  margin-top:20px;
}

#promo_code{
  margin-top:10px;
  text-transform:uppercase;
}

.valid_promo{
  color:#78D700;
  font-weight:700;
}

.invalid_promo{
  color:#F96D6D;
  font-weight:700;
}